<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="150px"
      >
        <div>
          <div :class="showPor1 && showPor2 ? 'cont-source' : ''">
            <p class="cont-source-title" v-if="showPor1 && showPor2"><span class="tips-red">*</span>原材料来源</p>
            <el-row v-if="showPor1">
              <el-col :span="24">
                <el-form-item label="收购来源：" prop="pro1">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.pro1 }}</p>
                  <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.pro1" placeholder="支持多选，选中后在下方展示！">
                    <el-button slot="append" @click="chooseType('acquisition')" type="primary">选择</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="showPor1 && ruleForm.pro4 && ruleForm.pro4.length">
              <el-col :span="24">
                <el-form-item label="" class="product-item">
                  <div class="product-list" v-for="(item, index) in ruleForm.pro4" :key="index">
                    <p>{{ item.label }}</p>
                    <i v-if="editType != 'look'" @click="delAcquisition(index)" class="el-icon-circle-close"></i>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="showPor2">
              <el-col :span="24">
                <el-form-item label="自产来源：" prop="pro2">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.pro2 }}</p>
                  <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.pro2" placeholder="支持多选，选中后在下方展示！">
                    <el-button slot="append" @click="chooseType('getproductlist1')" type="primary">选择</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="showPor2 && ruleForm.pro5 && ruleForm.pro5.length">
              <el-col :span="24">
                <el-form-item label="" class="product-item">
                  <div class="product-list" v-for="(item, index) in ruleForm.pro5" :key="index">
                    <p>{{ item.label }}</p>
                    <i v-if="editType != 'look'" @click="delSelfproduct(index)" class="el-icon-circle-close"></i>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <p v-if="ruleForm.fromType == '02' && editType != 'look'" class="tips-red pl20 pb10 pl36">请选择产品类型为预制菜和混装菜的产品！</p>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品名称：" prop="productName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productName }}</p>
                <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.productName" placeholder="请选择产品名称">
                  <el-button slot="append" @click="chooseType('getproductlist2')" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p v-if="editType != 'look'" class="tips-red pl20 pb10 pl36">生产批次为空时，从“生产档案”-“创建产品批次档案”录入数据。</p>
          <el-row>
            <el-col :span="24">
              <el-form-item label="生产批次：" prop="prpductBatch">
                <el-select v-model="ruleForm.prpductBatch" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择生产批次'" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.prpductBatch"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="总数量：" prop="allCount">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.allCount + ruleForm.countUnit}}</p>
                <el-input v-else v-model="ruleForm.allCount" placeholder="仅对本批次合格证有效！" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.countUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.allCount"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="总重量：" prop="allWeight">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.allWeight + ruleForm.weightUnit}}</p>
                <el-input v-else v-model="ruleForm.allWeight" placeholder="仅对本批次合格证有效！" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.weightUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.allWeight"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="whoCreator == 'buyer' ? '收购者' : '生产者'" prop="bodyName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyName }}</p>
                <el-input clearable v-else v-model="ruleForm.bodyName" :placeholder="whoCreator == 'buyer' ? '请输入收购者' : '请输入生产者'" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="联系电话：" prop="linkmanPhoneNumber">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.linkmanPhoneNumber }}</p>
                <el-input clearable v-else v-model="ruleForm.linkmanPhoneNumber" placeholder="请输入联系电话" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产地：" prop="productionAddress">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productionAddress }}</p>
                <!-- <el-input clearable v-else v-model="ruleForm.productionAddress" placeholder="请输入销售价格"></el-input>  -->
                <el-input class="my-input-btn my-input-btn1" v-else :disabled="true" placeholder="请选择" suffix-icon="el-icon-arrow-down" @click.native="chooseType('getpickarea')" v-model="ruleForm.productionAddress"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="!(editType == 'look' && !ruleForm.baseName)">
            <el-col :span="24">
              <el-form-item label="" prop="baseName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.baseName }}</p>
                <el-input v-else v-model="ruleForm.baseName" placeholder="仅输入基地名称，没基地时可以为空。" maxlength=100 show-word-limit class="input-with-select-blue">
                  <el-select v-model="historyName" @change="historyChange" slot="append" placeholder="可选择历史名称">
                    <el-option
                      v-for="(item, index) in selectObj.baseNames"
                      :key="index"
                      :label="item.baseName"
                      :value="item.baseName">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="开具日期" prop="issueTime">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.issueTime }}</p>
                <el-input clearable v-else v-model="ruleForm.issueTime" placeholder="请输入开具日期" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p class="tips-gray pb20 tips-line">承诺达标合格证开具日期不是产品的生产日期，开具时可以在生产日期之前，也可以在生产日期之后。仅代表本批次产品的承诺达标日期。</p>
          <el-row class="cont-source1">
            <el-col :span="24">
              <el-form-item label="合格承诺：" prop="promiseNum">
                <el-checkbox-group v-model="ruleForm.promiseNum" :disabled="editType == 'look'">
                  <el-checkbox v-if="whoCreator == 'productor'" v-for="(item, index) in selectObj.promiseNum1" :key="index" :label="item" :disabled="true">{{item}}</el-checkbox>
                  <el-checkbox v-if="whoCreator == 'buyer'" v-for="(item, index) in selectObj.promiseNum2" :key="index" :label="item" :disabled="true">{{item}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="cont-source1">
            <el-col :span="24">
              <el-form-item label="承诺依据：" prop="gistNum">
                <el-checkbox-group v-model="ruleForm.gistNum" :disabled="editType == 'look'">
                  <!-- <el-checkbox v-for="(item, index) in selectObj.gistNum" :label="item.value" :key="index">{{item.label}}</el-checkbox> -->
                  <el-checkbox label="gistNum1">质量安全控制符合要求</el-checkbox>
                  <el-checkbox :disabled="true" label="gistNum2">自行检测合格<span class="tips-red">(上传自检报告会自动打勾)</span></el-checkbox>
                  <el-checkbox :disabled="true" label="gistNum3">委托检测合格<span class="tips-red">(上传委托检测报告会自动打勾)</span></el-checkbox>
                  <!-- <el-checkbox v-if="editType == 'look'" :disabled="true" label="自我承诺">自我承诺</el-checkbox> -->
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" v-if="editType == 'look'">
              <el-form-item label="合格证预览：">
                <div class="width100 preview-img">
                  <my-image 
                    :src="ruleForm.cscImageUrl" 
                    :preview-src-list="[ruleForm.cscImageUrl]">
                  </my-image>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editType != 'look'">
            <el-col :span="24">
              <el-form-item label="自检报告：">
                <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="choosePics('inspectionReport')">历史自检报告</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="editType != 'look' ? '' : '自检报告：'" prop="inspectionReportUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.inspectionReportUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.inspectionReportUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.inspectionReportUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('inspectionReportUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editType != 'look'">
            <el-col :span="24">
              <el-form-item label="委托检测报告：">
                <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="choosePics('thirdInspectionReport')">历史委托检测报告</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="editType != 'look' ? '' : '委托检测报告：'" prop="thirdInspectionReportUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.thirdInspectionReportUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.thirdInspectionReportUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.thirdInspectionReportUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('thirdInspectionReportUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="收货单(收购凭证)：" prop="receivingNoteUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.receivingNoteUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.receivingNoteUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.receivingNoteUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('receivingNoteUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editType != 'look'">
            <el-col :span="24">
              <el-form-item label="产品图片：">
                <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="choosePics('productUrl2')">历史图片</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="editType != 'look' ? '' : '产品图片：'" prop="productUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.productUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.productUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.productUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('productUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="from == 'papersList'">
            <el-col :span="24">
              <el-form-item label="" label-width="60px">
                <el-checkbox @change="checkEntity" :disabled="editType == 'look'" v-model="ruleForm.certificateOfComplianceType">使用合格证专用电子印章</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button type="primary" :loading="loading" size="small" @click="onPreview()">扫码预览效果</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>

    <!-- 委托检测报告 -->
    <pics ref="thirdInspectionReportUrl" dictCode="104002" :businessCode="ruleForm.certificateOfComplianceId" :imgSize="0.5" propName="thirdInspectionReportUrl" storeTableName="examiningReportFileDirectoryTable" @getpics="getpics"></pics>

    <!-- 收货单（收购凭证） -->
    <pics ref="receivingNoteUrl" dictCode="105001" :businessCode="ruleForm.certificateOfComplianceId" :imgSize="0.5" propName="receivingNoteUrl" storeTableName="purchchaseDocumentDirectoryTable" @getpics="getpics"></pics>

    <!-- 产品图片 -->
    <pics ref="productUrl" dictCode="103003" showcrop :businessCode="ruleForm.certificateOfComplianceId" :imgSize="0.5" propName="productUrl" storeTableName="certificateOfComplianceProductImageDirectoryTable" @getpics="getpics"></pics>

    <!-- 自检报告 -->
    <pics ref="inspectionReportUrl" dictCode="104001" :businessCode="ruleForm.certificateOfComplianceId" :imgSize="0.5" propName="inspectionReportUrl" storeTableName="certificateOfComplianceFileDirectoryTable" @getpics="getpics"></pics>

    <!-- 自产来源 -->
    <productList ref="getproductlist1" @getproductlist="getselfproduct" tempType="01" titleName="主体产品"></productList>

    <!-- 产品名称 -->
    <productList ref="getproductlist2" @getproductlist="getproductlist" tempType="01" titleName="主体产品" :fromType="ruleForm.fromType"></productList>

    <!-- 收购来源 -->
    <acquisition ref="acquisition" @getacquisition="getacquisition"></acquisition>

    <pick-area showBtn="two3" ref="getpickarea" @getpickarea="getpickarea"></pick-area>

    <!-- 自检报告：历史自检报告 -->
    <show-pics class="inspectionReport" ref="inspectionReport" :allpics="picsObj.inspectionReportUrl" title="历史自检报告" propName="inspectionReportUrl" @getshowpics="getshowpics"></show-pics>

    <!-- 委托检测报告：历史自检报告 -->
    <show-pics class="thirdInspectionReport" ref="thirdInspectionReport" :allpics="picsObj.thirdInspectionReportUrl" title="历史委托检测报告" propName="thirdInspectionReportUrl" @getshowpics="getshowpics"></show-pics>

    <!-- 产品图片：历史图片 -->
    <show-pics class="productUrl" ref="productUrl2" :allpics="picsObj.productUrl" title="历史图片" propName="productUrl" @getshowpics="getshowpics"></show-pics>
    
    <source-page ref="sourcePage"></source-page>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import productList from '@/views/common/productList.vue'
import acquisition from '@/views/common/acquisition.vue'
import pickArea from '@/views/common/pickArea.vue'
import showPics from '@/views/common/showPics.vue'
import pics from '@/views/common/pics.vue'
// import myImage from '@/components/myImages/myImage.vue'
import sourcePage from './sourcePage.vue'
let _t = null
export default {
  name: "papersEdit",
  components: {
    pics,
    productList,
    pickArea,
    acquisition,
    showPics,
    sourcePage
  },
  props:{
  },
  data() {
    return {   
      rules: {
        pro1: [{ required: true,  trigger: "change", message: '请选择收购来源'}],
        pro2: [{ required: true,  trigger: "change", message: '请选择自产来源'}],
        productName: [{ required: true,  trigger: "change", message: '请选择产品名称'}],
        productionAddress: [{ required: true,  trigger: "change", message: '请选择产地'}],
        bodyName: [{ required: true,  trigger: "change", message: '请输入'}],
        gistNum: [{ required: true,  trigger: "change", 
          validator: (rule, val, callback) => {
            val = val || []
            if(val.length > 0){
              callback()
            }else{
              callback(new Error("请选择承诺依据"))
            }
          }
        }],
        allCount: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入总数量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        allWeight: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入总重量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        linkmanPhoneNumber: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入联系电话"))
            }else if(!baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      initForm: {
        certificateOfComplianceId: '',
        productId: '',
        productName: '',
        prpductBatch: '',
        allCount: '',
        countUnit: '盒',
        allWeight: '',
        weightUnit: '千克',
        userId: utils.getUserId(),
        bodyId: utils.getBodyId(),
        bodyType: utils.getBodyType(),
        bodyName: utils.getBodyName(),
        linkmanPhoneNumber: utils.getMobilePhoneNumber(),
        productionAddress: utils.getdefaultAddress(true),
        baseName: '',
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        county: '',
        countyCode: '',
        town: '',
        townCode: '',
        village: '',
        villageCode: '',
        gistNum1: '02',
        gistNum2: '02',
        gistNum3: '02',
        promiseNum: [],
        gistNum: [],
        inspectionReportUrl: [],
        thirdInspectionReportUrl: [],
        receivingNoteUrl: [],
        productUrl: [],
        businessBodyType: '',
        supplierName: '',
        certificateOfComplianceFileDirectoryTable: '',
        examiningReportFileDirectoryTable: '',
        certificateOfComplianceProductImageDirectoryTable: '',
        purchaseDocumentDirectoryTable: '',
        pro1: '',
        pro2: '',
        pro4: [],
        pro5: [],
        issueTime: utils.dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        pro3: utils.getdefaultAddress(),
        fromType: '',
        certificateOfComplianceType: false
      },
      selectObj: {
        prpductBatch: [],
        baseNames: [],
        promiseNum1: ['不使用禁用农药兽药、停用兽药和非法添加物', '常规农药兽药残留不超标', '对承诺的真实性负责'],
        promiseNum2: ['已收取并保存该批次农产品的承诺达标合格证或者其他质量安全合格证明', '不违规使用保鲜剂、防腐剂、添加剂等', '对承诺的真实性负责'],
        gistNum: [
          { label: '质量安全控制符合要求', value: 'gistNum1' },
          { label: '自行检测合格', value: 'gistNum2' },
          { label: '委托检测合格', value: 'gistNum3' },
        ],
        allCount: CONSTPARAMS.snumUnit,
        allWeight: CONSTPARAMS.saleUnit
      },
      historyName: '历史名称',
      picsObj: {
        inspectionReportUrl: [],
        thirdInspectionReportUrl: [],
        receivingNoteUrl: [],
        productUrl: [],
        examiningReportFileDirectoryTable: '',
        certificateOfComplianceProductImageDirectoryTable: '',
        purchaseDocumentDirectoryTable: ''
      },
      showPor1: false,
      showPor2: false,
      defaultAddress: [],
      from: '',
      whoCreator: 'productor', //buyer
      promiseNum1: ['不使用禁用农药兽药、停用兽药和非法添加物', '常规农药兽药残留不超标', '对承诺的真实性负责'],
      promiseNum2: ['已收取并保存该批次农产品的承诺达标合格证或者其他质量安全合格证明', '不违规使用保鲜剂、防腐剂、添加剂等', '对承诺的真实性负责'],
    }
  },
  created() {
    this.getBaseName()
	},
  mounted() {
    _t = this
  },
  methods: {
    selwhoCreator(){
      let val = this.ruleForm.pro5 || [],
        fromType = this.ruleForm.fromType
      if(fromType == '03' || (fromType == '02' && val.length)){
        this.whoCreator = 'productor'
        this.ruleForm.promiseNum = this.promiseNum1
      }else{
        this.whoCreator = 'buyer'
        this.ruleForm.promiseNum = this.promiseNum2
      }
    },
    initnormal(){
      let bodyType = this.ruleForm.bodyType,
        businessBodyType = this.ruleForm.businessBodyType
      if(bodyType == '12'){ //经营主体
        this.rules.pro1[0].required = true
        this.rules.pro2[0].required = false
        this.showPor1 = true
        this.showPor2 = false
      }else if(bodyType == '13'){ //生产/经营主体
        if(businessBodyType == '03'){ //自产
          this.rules.pro1[0].required = false
          this.rules.pro2[0].required = false
          this.showPor1 = false
          this.showPor2 = false
        }else if(businessBodyType == '02'){ //预制菜或混装菜
          this.rules.pro1[0].required = false
          this.rules.pro2[0].required = false
          this.showPor1 = true
          this.showPor2 = true
        }else if(businessBodyType == '01'){ //收购
          this.rules.pro1[0].required = true
          this.rules.pro2[0].required = false
          this.showPor1 = true
          this.showPor2 = false
        }else{ 
          this.rules.pro1[0].required = false
          this.rules.pro2[0].required = false
          this.showPor1 = false
          this.showPor2 = false
        }
      }else if(bodyType == '11'){ //生产主体
        this.rules.pro1[0].required = false
        this.rules.pro2[0].required = false
        this.showPor1 = false
        this.showPor2 = false
      }
    },
    choosePics(prop){
      this.$refs[prop].showModel()
    },
    getDtoArr(data = [], type, flag = false){
      let arr = []
      if(data.length){
        data.forEach(cur => {
          if(cur.channelType == type){
            arr.push(cur)
          }
        })
      }
      if(flag){
        return this.getRealLables(arr)
      }
      return arr
    },
    getproductlist(data){
      let oldId = this.ruleForm.productId

      this.ruleForm.productId = data.productId || ''
      this.ruleForm.productName = data.productName || ''

      if(oldId != data?.productId){
        this.getHistoryPics()
        this.getProductBatch()
        this.ruleForm.prpductBatch = ''
        this.ruleForm.productUrl = data?.productUrl || []
        this.ruleForm.certificateOfComplianceProductImageDirectoryTable = data?.productFileTable || ''
      }
    },
    getProductBatch(){
      let _this = this
      request.psccgetProductBatch(this.ruleForm.bodyId, this.ruleForm.productId).then(res => {
        if(res.code == 200){
          _this.tranProductBatch(res.data)
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getHistoryPics(){
      let _this = this
      request.psccgetHistoryFile(this.ruleForm.bodyId, this.ruleForm.productId).then(res => {
        if(res.code == 200){
          _this.tranPics(res.data)
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getshowpics(data){
      let propName = data.propName, 
        selPics = data.pics,
        allPics = this.picsObj[propName] || [],
        oldPics = this.ruleForm[propName],
        leftPics = [],
        obj = {
          inspectionReportUrl: 'certificateOfComplianceFileDirectoryTable',
          thirdInspectionReportUrl: 'examiningReportFileDirectoryTable',
          receivingNoteUrl: 'purchchaseDocumentDirectoryTable',
          productUrl: 'certificateOfComplianceProductImageDirectoryTable',
        }

      allPics.forEach(cur => {
        if(selPics.indexOf(cur) == -1){
          leftPics.push(cur)
        }
      })
    
      leftPics.length && leftPics.forEach(cur => {
        let index = oldPics.indexOf(cur)
        if(index > -1){
          oldPics.splice(index, 1)
        }
      })

      let pics = [
        ...oldPics
      ]

      selPics.forEach(cur => {
        if(pics.indexOf(cur) == -1){
          pics.push(cur)
        }
      })

      let tableName = obj[propName], 
        tableNameValue = this.picsObj[tableName] || '',
        realValue = this.ruleForm[tableName]
      this.ruleForm[propName] = pics
      this.ruleForm[tableName] = realValue ? realValue : tableNameValue
      this.setgistNum()
    },
    setgistNum(){
      let contObj = this.ruleForm,
        inspectionReportUrl = contObj.inspectionReportUrl || [],
        thirdInspectionReportUrl = contObj.thirdInspectionReportUrl || [],
        gistNum = contObj.gistNum || []
      // if(inspectionReportUrl.length && !gistNum.includes('gistNum2')){
      //   this.ruleForm.gistNum = [...gistNum, 'gistNum2']
      // }
      // if(thirdInspectionReportUrl.length && !gistNum.includes('gistNum1')){
      //   this.ruleForm.gistNum = [...gistNum, 'gistNum1']
      // }
      if(inspectionReportUrl.length && !gistNum.includes('gistNum2')){
        this.ruleForm.gistNum = [...gistNum, 'gistNum2']
      }else if(!inspectionReportUrl.length && gistNum.includes('gistNum2')){
        let index = gistNum.indexOf("gistNum2")
        if(index > -1){
          gistNum.splice(index,1)
        }
        this.ruleForm.gistNum = gistNum
      }
      if(thirdInspectionReportUrl.length && !gistNum.includes('gistNum3')){
        this.ruleForm.gistNum = [...gistNum, 'gistNum3']
      }else if(!thirdInspectionReportUrl.length && gistNum.includes('gistNum3')){
        let index = gistNum.indexOf("gistNum3")
        if(index > -1){
          gistNum.splice(index,1)
        }
        this.ruleForm.gistNum = gistNum
      }
    },
    tranPics(data){
      let pics = {
        inspectionReportUrl: [],
        thirdInspectionReportUrl: [],
        receivingNoteUrl: [],
        productUrl: [],
        examiningReportFileDirectoryTable: '',
        certificateOfComplianceProductImageDirectoryTable: '',
        purchaseDocumentDirectoryTable: '',
      }
      data.forEach(cur => {
        for(let item in pics){
          if(item == 'examiningReportFileDirectoryTable' || item == 'certificateOfComplianceProductImageDirectoryTable' ||
          item == 'purchaseDocumentDirectoryTable'){
            if(cur[item]){
              pics[item] = cur[item]
            }
          }else if(cur[item]){
            if(cur[item]){
              let pic = cur[item] ? cur[item].split(',') : []
              pics[item] = this.removeDuplicate(pic)
            }
          }
        }
      })
      this.picsObj = pics
    },
    removeDuplicate(arr) {
      let newArr = []
      arr.forEach(item => {
        if (!newArr.includes(item)) {
          newArr.push(item)
        }
      })
      return newArr
    },
    tranProductBatch(data){
      let arr = []
      if(data.length){
        data.forEach(cur => {
          arr.push({
            value: cur.docId,
            label: `${cur.docNum}/${cur.batchDate}`
          })
        })
      }
      this.selectObj.prpductBatch = arr
    },
    getBaseName(){
      let _this = this
      request.psccgetHistoryBaseName(utils.getBodyId()).then(res => {
        if(res.code == 200){
          _this.selectObj.baseNames = res.data || []
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    delAcquisition(index){
      let data = this.ruleForm.pro4
      data.splice(index, 1)
      let label = this.getRealLables(data)
      this.ruleForm.pro4 = data
      this.ruleForm.pro1 = label || ''
    },
    getselfproduct(cont){
      let id = cont.productId,
        name = `${cont.productName || ''}/${cont.productTypeName || ''}(${cont.productCode || ''})`,
        data = this.ruleForm.pro5 || [],
        pro2 = this.ruleForm.pro2,
        flag = true

      data.forEach(cur => {
        if(id == cur.mixtureProductId) flag = false
      })
      if(!flag){
        this.message('已添加此条记录！', "warning")
        return
      }
      data.push({
        transactionRecordId: '',
        mixtureProductId: id,
        certificateOfComplianceId: this.ruleForm.certificateOfComplianceId,
        channelType: '01', //自产
        label: name
      })
      this.ruleForm.pro5 = data
      this.ruleForm.pro2 = pro2 ? `${pro2},${name}` : `${name}`
      this.selwhoCreator()
    },
    delSelfproduct(index){
      let data = this.ruleForm.pro5
      data.splice(index, 1)
      let label = this.getRealLables(data)
      this.ruleForm.pro5 = data
      this.ruleForm.pro2 = label || ''
      this.selwhoCreator()
    },
    getRealLables(data = []){
      let val = []
      if(data.length){
        data.forEach(cur => {
          val.push(cur.label || '')
        })
      }
      return val.join(',')
    },
    getacquisition(cont){
      let id = cont.transactionRecordId,
        name = `${cont.createTime}/${cont.supplierName || ''}/${cont.productName || ''}/${(cont.practicaWeight || '') + (cont.weightUnit || '')}`,
        data = this.ruleForm.pro4,
        pro1 = this.ruleForm.pro1,
        flag = true

        data.forEach(cur => {
          if(id == cur.transactionRecordId) flag = false
        })
        if(!flag){
          this.message('已添加此条记录！', "warning")
          return
        }
        data.push({
          transactionRecordId: id,
          mixtureProductId: '',
          certificateOfComplianceId: this.ruleForm.certificateOfComplianceId,
          channelType: '02', //收购
          label: name
        })
        this.ruleForm.pro4 = data
        this.ruleForm.pro1 = pro1 ? `${pro1},${name}` : `${name}`
        this.setAddress(cont || {})
    },
    historyChange(data){
      this.historyName = '历史名称'
      this.ruleForm.baseName = data
    },
    chooseType(name){
      if(name == 'getpickarea'){
        this.$refs[name].initData(utils.getAddressByObj(this.ruleForm) || [])
        return
      }
      this.$refs[name].initData()
    },
    checkEntity(){
      let _this = this
      request.psgetUserAuthenticationEntity({
        bodyId: utils.getBodyId(),
        bodyName: utils.getBodyName(),
      }).then(res => {
        if(res.code == 200){
          if(res.data.flag){
          }else{
            this.$alert(res.data?.msg || '', '提示', {
              confirmButtonText: '确定',
              callback: () => {
              }
            })
            _this.ruleForm.certificateOfComplianceType = false
          }
        }else{
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getpickarea(data){
      let address = ''
      data.forEach(cur => {
        if(cur.areaCode){
          address += cur.areaName
        }
      })
      this.ruleForm.province = data[0] && data[0].areaName || ''
      this.ruleForm.provinceCode = data[0] && data[0].areaCode || ''
      this.ruleForm.city = data[1] && data[1].areaName || ''
      this.ruleForm.cityCode = data[1] && data[1].areaCode || ''
      this.ruleForm.county = data[2] && data[2].areaName || ''
      this.ruleForm.countyCode = data[2] && data[2].areaCode || ''
      this.ruleForm.town = data[3] && data[3].areaName || ''
      this.ruleForm.townCode = data[3] && data[3].areaCode || ''
      this.ruleForm.village = data[4] && data[4].areaName || ''
      this.ruleForm.villageCode = data[4] && data[4].areaCode || ''
      this.ruleForm.productionAddress = address || ''
    },
    setAddress(addr){
      let data = addr || utils.getStore('userInfo')?.bodyAreaInfo || {}
      this.ruleForm.province = data.province || ''
      this.ruleForm.provinceCode = data.provinceCode || ''
      this.ruleForm.city = data.city || ''
      this.ruleForm.cityCode = data.cityCode || ''
      this.ruleForm.county = data.county || ''
      this.ruleForm.countyCode = data.countyCode || ''
      this.ruleForm.town = data.town || ''
      this.ruleForm.townCode = data.townCode || ''
      this.ruleForm.village = data.village || ''
      this.ruleForm.villageCode = data.villageCode || ''
      if(data){
        this.ruleForm.productionAddress = (data.province || '') + (data.city || '') + (data.county || '') + (data.town || '') + (data.village || '')
      }
    },
    addPics(name){
      this.$refs[name].initData(this.ruleForm[name])
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName)
      this.setgistNum()
    },
    async initData(title, editType, ruleForm, transobj = {}, fromName){
      this.from = fromName
      if(editType == 'add'){
        let id = await utils.getNewId(),
        // let id = '',
          obj = {
          ...this.initForm,
          ...transobj,
          certificateOfComplianceId: id,
          certificateOfComplianceType: fromName == 'papersList' ? false : true
        }
        this.defaultAddress = []
        this.ruleForm = obj
      }else{
        this.ruleForm = ruleForm
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      this.initnormal()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
      this.selwhoCreator()
    },
    initAdd(){
      this.setAddress()
      this.defaultAddress = utils.getAddressByObj(this.ruleForm)
    },
    initEdit(){
      this.ruleForm.pro4 = this.getDtoArr(this.ruleForm.mixtureProductDTOList, '02')
      this.ruleForm.pro5 = this.getDtoArr(this.ruleForm.mixtureProductDTOList, '01')
      this.ruleForm.pro2 = this.getDtoArr(this.ruleForm.mixtureProductDTOList, '01', true)
      this.ruleForm.pro1 = this.getDtoArr(this.ruleForm.mixtureProductDTOList, '02', true)
      this.getBaseName()
      this.getHistoryPics()
      this.getProductBatch()
      this.defaultAddress = utils.getAddressByObj(this.ruleForm)
    },
    initLook(){
      this.initEdit()
    },
    onPreview(){
      if(this.editType == 'look'){
        this.linkTo()
        return
      }
      this.submitForm(true)
    },
    linkTo(code){
      code = code || this.ruleForm.certificateOfComplianceBathCode
      this.$refs.sourcePage.initData(code)
    },
    getBathCode(code){
      let _this = this
      request.psccgetInfo(code).then(res => {
        if(res.code == 200){
          let code = res.data?.certificateOfComplianceBathCode
          if(code){ 
            _this.linkTo(code)
          }else{
            _this.message('获取数据失败', "error")
          }
        }else{
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
        _this.loading = false
      })
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let contObj = _this.ruleForm
          if(_this.showPor1 && _this.showPor2){
            if(!contObj.pro1 && !contObj.pro2){
              _this.message('收购来源跟自产来源不能同时为空！', "warning")
              return
            }
          }
          let ways = editType == 'add' ? 'psccadd' : 'psccmodify',
            target = {
            ...contObj,
            // cscImageUrl: contObj.cscImageUrl?.join(',') || '',
            productUrl: contObj.productUrl?.join(',') || '',
            inspectionReportUrl: contObj.inspectionReportUrl?.join(',') || '',
            thirdInspectionReportUrl: contObj.thirdInspectionReportUrl?.join(',') || '',
            receivingNoteUrl: contObj.receivingNoteUrl?.join(',') || '',
            gistNum1: contObj.gistNum.indexOf('gistNum1') > -1 ? '01' : '02',
            gistNum2: contObj.gistNum.indexOf('gistNum2') > -1 ? '01' : '02',
            gistNum3: contObj.gistNum.indexOf('gistNum3') > -1 ? '01' : '02',
            allWeight: parseFloat(contObj.allWeight || 0),
            pro4: '',
            pro5: '',
            pro3: '',
            promiseNum: '',
            gistNum: '',
            mixtureProductDTOList: [...contObj.pro4, ...contObj.pro5],
            certificateOfComplianceType: contObj.certificateOfComplianceType ? '03' : '01',
            userId: utils.getUserId()
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              if(flag){
                if(editType == 'add'){
                  _this.getBathCode(contObj.certificateOfComplianceId)
                  _this.editType = 'edit'
                }else{
                  _this.linkTo()
                } 
              }else{
                _this.message(mesInfo[editType], "success")
                _this.closeModel()
                _this.$emit("getData", editType == 'add' ? true : false)
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .product-item  ::v-deep .el-form-item__content{
    background-color: #fbfbfb;
    border: 1px solid #f3f3f3;
  }
  .product-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    padding: 0 10px;
  }
  .product-list i{
    cursor: pointer;
    font-size: 18px;
  }
  .cont-source{
    border: 1px dashed #c9c3c3;
    padding: 10px;
    margin-bottom: 20px;
  }
  .cont-source-title{
    margin-left: 58px;
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .tips-line{
    padding-left: 60px;
  }
  .pl36{
    padding-left: 36px;
  }
</style>


