<template>
  <papersList :breadStaticData="breadStaticData"></papersList>
</template>
<script>
import papersList from './papersList.vue'
export default {
  name: "nopaperList",
  components: {
    papersList,
  },
  props:{
  },
  data(){
    return {
      breadStaticData: ['承诺达标合格证管理', '开具数字合格证（无纸化转发）']
    }
  },
  created() {
	},
  mounted() {
  },
}
</script>